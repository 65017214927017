span {
    font-size: 30px;
}

a {
    color: #000000;
    text-decoration: none;
}

a:hover {
    color: #000000;
    text-decoration: none;
    cursor: pointer;
}